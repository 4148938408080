@import '../../base.scss';
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

$font-family2: 'Dancing Script', cursive;

.navbar {
    background-color: white;

    // height: 70px;
}
.nav__spacer {
    margin-bottom: 0px;
    @include media-breakpoint-down(xl) {
        margin-bottom: 40px;
    }
    @include media-breakpoint-down(lg) {
        margin-bottom: 70px;
    }
}

.nav__header {
    // border-bottom: 1px solid $primary-color;
    background-color: white;
}

.navbar-brand {
    color: $primary-color;
    font-family: $font-family2;
    font-size: 35px;
    font-weight: 600;
}

.nav-link {
    color: $primary-color-dark;
    font-size: 17px;
    font-weight: 500;
    margin-right: 20px;
}

.nav__button {
    font-size: 16px;
    font-weight: 500;
    height: 50px;
    padding: 10px 15px;

    &:hover {
        color: white;
    }
}
.nav__button2 {
    font-size: 14px;
    font-weight: 400;

    &:hover {
        color: $primary-color;
    }
}
.nav__icon {
    font-size: 10px;
}

.navbar__logo {
    height: 30px;
}

.navbar-toggler {
    color: $primary-color-dark;
}
.nav__drop__header {
    font-weight: 500;
}
.nav__drop__text {
    font-size: 13px;
    font-weight: 400;
}

.nav__drop__icon {
    color: $primary-color-dark;
    font-size: 20px;
    background-color: $secondary-color-light;
    padding: 10px;
    border-radius: 5px;
}

.nav__drop__links {
    text-decoration: none;
    color: $primary-color-dark;

    &:hover {
        .nav__drop__icon {
            color: $primary-color;
        }
    }
}

@include media-breakpoint-up(lg) {
    .dropdown-menu {
        width: 400px;
        left: 50% !important;
        right: auto !important;
        border: 1px solid $tertiary-color-light;
        transform: translate(-50%, 0) !important;
    }
    .mobile-buttons {
        display: none;
    }
    .desktop-buttons {
        display: block;
    }
}
@include media-breakpoint-down(lg) {
    .mobile-buttons {
        text-align: center;
        display: block;
    }
    .desktop-buttons {
        display: none;
    }
    .dropdown-menu {
        border: 0;
        animation: fadeIn;
        animation-duration: 2s;
    }
    .navbar-collapse {
    }
}
.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; // remove the gap so it doesn't close
}

.progress {
    border-radius: 0;
    height: 3px;
}

.navbar__progress {
    background-color: $primary-color;
}

.bounce {
    animation: bounce;
    animation-duration: 2s;
}
