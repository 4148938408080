@import './colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mate+SC&display=swap');
$primary: $primary-color;
$secondary: $secondary-color-dark;
$theme-colors: (
    'primary': $primary,
    'warning': $primary,
    'secondary': $secondary
);

@import '../node_modules/bootstrap/scss/bootstrap.scss';
$font-family1: 'Montserrat', sans-serif;
$font-family2: 'Mate SC', serif;

body {
    margin: 0;
    font-family: $font-family1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*   background-color: #141d26 !important;
    color: white !important; */
    /* width */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $accent-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $misc-color-extra;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $misc-color-extra;
}
