.slideDown {
    animation: slideInDown;
    animation-duration: 2s;
}

.slideUp {
    animation: slideInUp;
    animation-duration: 2s;
}

.slideLeft {
    animation: slideInLeft;
    animation-duration: 2s;
}

.slideRight {
    animation: slideInRight;
    animation-duration: 1s;
}
.fadeIn {
    animation: fadeIn;
    animation-duration: 2s;
}

.bubbles {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    top: 0;
    left: 0;
}
