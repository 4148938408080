@import '../../base.scss';

.first__section {
    color: $primary-color-dark;
    margin-top: 100px;
    // min-height: 100px;
    /*  background-color: red; */
    .first__section__image {
        height: 280px;
        width: 270px;
        // float: left;
        background: hsla(28, 100%, 65%, 1);

        background: linear-gradient(
            180deg,
            hsla(28, 100%, 65%, 1) 0%,
            hsla(28, 100%, 87%, 1) 100%
        );

        background: -moz-linear-gradient(
            180deg,
            hsla(28, 100%, 65%, 1) 0%,
            hsla(28, 100%, 87%, 1) 100%
        );

        background: -webkit-linear-gradient(
            180deg,
            hsla(28, 100%, 65%, 1) 0%,
            hsla(28, 100%, 87%, 1) 100%
        );

        border-radius: 25px;
    }

    .work__button {
        width: 200px;
        color: white;
    }

    .my__cv {
        box-shadow: 10px 4px 37px -13px rgba(255, 160, 77, 0.75);
        -webkit-box-shadow: 10px 4px 37px -13px rgba(255, 160, 77, 0.75);
        -moz-box-shadow: 10px 4px 37px -13px rgba(255, 160, 77, 0.75);

        &:hover {
            color: white;
        }
    }

    .header__image {
        text-align: center;
        @include media-breakpoint-down(lg) {
            margin-top: 80px;
        }
    }
    .first__section__header {
        font-size: 25px;
        font-weight: 500;

        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }
    .first__section__secondary {
        font-size: 30px;
        font-weight: 500;
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }
    .first__section__buttons {
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }

    .header__extra {
        font-size: 12px;
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }
}

.second__section {
    margin-top: 100px;

    .second__section__header {
        font-size: 27px;
        font-weight: 550;
        @include media-breakpoint-down(xl) {
            text-align: center;
        }
    }

    .second__section__secondary__header {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }
    .second__section__images__container {
        text-align: center;
    }
    .second__section__images {
        height: 50px;
    }
    .ninth__section__images {
        height: 90px;
    }
    .second__section__secondary {
        text-align: center;
    }
}

.third__section {
    margin-top: 150px;

    .third__section__image {
        height: 500px;
    }

    .third__section__image__container {
        text-align: center;
        @include media-breakpoint-down(xl) {
            display: none;
        }
    }

    .third__section__header {
        font-size: 27px;
        font-weight: 550;
        @include media-breakpoint-down(xl) {
            text-align: center;
        }
    }
    .third__section__icons {
        text-align: center;
        img {
            height: 36px;

            &:hover {
                animation: headShake;
                animation-duration: 1s;
            }
        }
        i {
            font-size: 50px;
        }
        p {
            font-size: 10px;
        }
        .intel {
            height: 20px !important;
        }
    }
    .dev__icons {
        text-align: center;
    }

    .icons__inline {
        display: inline-block;
    }
}

.back-to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    display: none;
    background-color: $primary-color;
    color: white;

    &:hover {
        color: white;
    }
}
.focus__text {
    color: $primary-color;
}
.focus__text__link {
    color: $primary-color;
    text-decoration: none;
}

.fourth__section {
    margin-top: 150px;
    .fourth__section__header {
        font-size: 27px;
        font-weight: 550;
        @include media-breakpoint-down(xl) {
            text-align: center;
        }
    }

    .big__project {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 20px;
        .small__screen {
            display: none;
        }

        @include media-breakpoint-down(lg) {
            text-align: center;
            padding-bottom: 50px;

            .small__screen {
                display: block;
            }
            .large__screen {
                display: none;
            }
        }
        .note {
            font-size: 12px;
            font-weight: 500;
            color: $primary-color;
        }
        .project__main__image {
            -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.14);
            box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.14);
            height: 500px;
            width: 350px;
            @include media-breakpoint-down(lg) {
                margin-top: 1rem;
            }
        }
        .project__main__image2 {
            -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.14);
            box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.14);
            height: 300px;
            width: 500px;
            @include media-breakpoint-down(lg) {
                margin-top: 1rem;
            }
            @include media-breakpoint-down(md) {
                margin-top: 1rem;
                height: 200px;
                width: 280px;
            }
            @media screen and (max-width: 330px) {
                margin-top: 1rem;
                height: 150px;
                width: 220px;
            }
        }
        .name {
            font-size: 25px;
            font-weight: 500;
        }
        .type {
            font-size: 15px;
            font-weight: 500;
        }
        .project__icon {
            height: 30px;
        }
        a {
            text-decoration: none;
        }

        .icon__light {
            height: 20px !important;
            width: 20px;
            margin-right: 5px;
        }
        ul {
            list-style: none;
            padding: 0;
        }
    }
    .small__project {
        // height: 800px;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        @include media-breakpoint-down(xl) {
            padding-bottom: 50px;
        }
        text-align: center;

        .project__main__image {
            -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.14);
            box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.14);
            height: 280px;
            width: 500px;
            @include media-breakpoint-down(xxl) {
                // margin-top: 1rem;
                height: 250px;
                width: 400px;
            }
            @include media-breakpoint-down(xl) {
                margin-top: 1rem;
                height: 250px;
                width: 400px;
            }
            @include media-breakpoint-down(md) {
                margin-top: 1rem;
                height: 200px;
                width: 300px;
            }
            @media screen and (max-width: 330px) {
                margin-top: 1rem;
                height: 150px;
                width: 220px;
            }
        }
        .name {
            font-size: 25px;
            font-weight: 500;
        }
        .type {
            font-size: 15px;
            font-weight: 500;
        }
        .project__icon {
            height: 30px;
        }
        a {
            text-decoration: none;
        }

        .icon__light {
            height: 20px !important;
            width: 20px;
            margin-right: 5px;
        }
        ul {
            list-style: none;
            padding: 0;
        }

        li {
            margin-left: 0px;
        }

        .note {
            font-size: 12px;
            font-weight: 500;
            color: $primary-color;
        }
    }
    .tiny__project {
        // height: 800px;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 20px;
        text-align: center;

        .project__main__image {
            -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.14);
            box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.14);
            height: 180px;
            width: 280px;

            @include media-breakpoint-down(xxl) {
                height: 190px;
                width: 300px;
            }
            @media screen and (max-width: 330px) {
                margin-top: 1rem;
                height: 150px;
                width: 220px;
            }
        }
        .name {
            font-size: 25px;
            font-weight: 500;
        }
        .type {
            font-size: 15px;
            font-weight: 500;
        }
        .project__icon {
            height: 30px;
        }
        a {
            text-decoration: none;
        }

        .icon__light {
            height: 20px !important;
            width: 20px;
            margin-right: 5px;
        }
        ul {
            list-style: none;
            padding: 0;
        }

        .note {
            font-size: 12px;
            font-weight: 500;
            color: $primary-color;
        }
    }
    .tiny__project.shop {
        background-color: #fbf8ef;
    }
    .tiny__project.spawn {
        background-color: #fff8f8;
    }
    .tiny__project.tofie {
        background-color: #fff7f1;
    }
    .big__project.cedi {
        background-color: #fff7f1;
    }
    .small__project.hazegen {
        background-color: #f8fff8;
    }
    .small__project.blaze {
        background-color: #fff8f8;
    }
    .big__project.medic {
        background-color: #fbf8ff;
        .project__main__image {
            height: 250px !important;
            width: 380px !important;

            @include media-breakpoint-down(xxl) {
                // margin-top: 1rem;
                height: 250px !important;
                width: 400px !important;
            }
            @include media-breakpoint-down(md) {
                margin-top: 1rem;
                height: 200px !important;
                width: 280px !important;
            }
            @media screen and (max-width: 330px) {
                margin-top: 1rem;
                height: 150px !important;
                width: 220px !important;
            }
        }
    }
}

.sixth__section {
    margin-top: 150px;

    .sixth__section__header {
        font-size: 27px;
        font-weight: 550;
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }

    .sixth__section__secondary {
        font-size: 18px;
        font-weight: 500;
        margin-top: 60px;
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }
    .sixth__section__header__extra {
        font-size: 12px;
        font-weight: 400;
        color: $accent-color-extra;
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }

    .email {
        font-weight: 500;
        color: $primary-color;
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }
    a {
        text-decoration: none;
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }
    i {
        font-size: 15px;
    }
    .follow__icons {
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }
    .extra {
        &:hover {
            color: white;
        }
    }
    .contact__image {
        height: 500px;
        width: 500px;
    }
    .sixth__section__image {
        height: 280px;
        width: 270px;
        // float: left;
        background: hsla(28, 100%, 65%, 1);

        background: linear-gradient(
            180deg,
            hsla(28, 100%, 65%, 1) 0%,
            hsla(28, 100%, 87%, 1) 100%
        );

        background: -moz-linear-gradient(
            180deg,
            hsla(28, 100%, 65%, 1) 0%,
            hsla(28, 100%, 87%, 1) 100%
        );

        background: -webkit-linear-gradient(
            180deg,
            hsla(28, 100%, 65%, 1) 0%,
            hsla(28, 100%, 87%, 1) 100%
        );

        border-radius: 25px;
    }
    .contact__image {
        width: 100%;

        text-align: center;
        @include media-breakpoint-down(lg) {
            margin-top: 80px;
        }
    }
}

.footer__section {
    border-top: 1px solid $accent-color;
    text-align: center;
    color: $accent-color-extra;
}

.active {
    color: $primary-color;
}
