$primary-color: #ff9335;
$primary-color-dark: #180b00;
$secondary-color-dark: #09bc8a;
$secondary-color-light: #c7f0e5;
$tertiary-color-light: #ffebd9;
$accent-color: #ffddbf;
$accent-color-extra: #b3a599;
$accent-color-dark: #abe9d8;
$accent-color-darker: #abe9d8;
$misc-color: #ffa04d;
$misc-color-extra: #2f1600;
